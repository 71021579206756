<template>
  <div>
    <div class="text-center mb-5 cursor-pointer" @click="isAdding = !isAdding">
      <div>
        <b-button class="btn-icon rounded-circle">
          <feather-icon icon="PlusIcon" size="38" />
        </b-button>
      </div>
      <h4 class="mt-2">Add Element</h4>
    </div>

    <div class="d-flex" v-if="isAdding">
      <div style="width: 15%" />

      <div style="width: 30%" class="cursor-pointer" @click="add('fullWidth')">
        <div class="d-flex text-14px justify-content-center mb-1"> <div class="circle" /> Full Width </div>
        <b-card class="my-shadow" style="height: 250px" />
      </div>

      <div style="width: 10%" />

      <div style="width: 30%" class="cursor-pointer" @click="add('halfWidth')">
        <div class="d-flex text-14px justify-content-center mb-1"> <div class="circle" /> Half Width </div>
        <div class="d-flex">
          <b-card class="my-shadow" style="height: 250px; width: 47%" />
          <div style="width: 6%" />
          <b-card class="my-shadow" style="height: 250px; width: 47%" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BButton, BDropdown, BDropdownItem } from "bootstrap-vue";
import elements from "./elements"

export default {
  components: { BButton, BDropdown, BDropdownItem },
  data() {
    return {
      isAdding: false,
      openingItem: '',
      elements,
    };
  },
  methods: {
    add(type){
      this.isAdding = false,
      this.openingItem = '',
      this.$emit('add', type)
      if (type == 'halfWidth') 
        this.$emit('add', type)
    },
  },
};
</script>
<style>
</style>