<template>
   <current-user-stats :showToday="false"/>
</template>
<script>
import CurrentUserStats from "../../b-book/userStats/Index";
export default {
  components: {
    CurrentUserStats
  }
}
</script>