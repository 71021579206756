<template>
   <today-user-stats :showCurrent="false"/>
</template>
<script>
import TodayUserStats from "../../b-book/userStats/Index";
export default {
  components: {
    TodayUserStats
  }
}
</script>