import riskStyle from "@/utils/riskStyle"

export default {
  market_exposure: {
    name: "Market Exposure",
    children: {
      market_exposure: {
        name: "Market Exposure",
        component: "MarketExposureTable"
      },
      platform_open_positions: {
        name: "Platform Open Positions",
        component: "PlatformOpenPositionsTable"
      },
      trading_accounts: {
        name: "Trading Account Exposure",
        component: "TradingAccountTable"
      },
    }
  },
  user_statistics: {
    name: "User Statistics",
    children: {
      today_user_statistics: {
        name: "Today User Statistics",
        component: "TodayUserStatisticsTable"
      },
      current_user_statistics: {
        name: "Current User Statistics",
        component: "CurrentUserStatisticsTable"
      }
    }
  },
  company_pl: {
    name: "Company PL",
    children: {
      company_pl_widgets: {
        name: "Company PL Widgets",
        component: "CompanyPLWidgets"
      },
      company_pl: {
        name: "Company PL",
        component: "PLTable"
      },
      pl_chart: {
        name: "PL Chart",
        component: "ProfitLossChart"
      },
      equity_chart: {
        name: "Equity Chart",
        component: "EquityChart"
      },
      dw_chart: {
        name: "Deposit/WD Chart",
        component: "DWChart"
      },
      trading_volume_chart: {
        name: "Trading Volume Chart",
        component: "TradingVolumeChart"
      },
    }
  },
  deepdive: {
    name: "Deepdive",
    children: {
      account_analysis: {
        name: "Account Analysis",
        component: "AccountAnalysisTable"
      },
      account_details: {
        name: "Account Analysis Detail",
        component: "AccountDetails",
        props: {
          ref: "account_details",
          show2ndComponent: false
        }
      },
      account_pl: {
        name: "Account PL",
        component: "AccountPLTable",
        props: {
          show1stComponent: false,
          show2ndTimeframe: true
        }
      },
      acccount_profit: {
        name: "Account Profit Chart",
        component: "ClientProfitHistoryCharts"
      },
      account_equity: {
        name: "Account Equity Chart",
        component: "ClientEquityHistoryCharts"
      },
    }
  }
}