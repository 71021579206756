<template>
    <b-container fluid class="px-0">
        <draggable :list="settings" class="row" @end="set_localStorage">
            <div
                class="d-flex pl-50"
                v-for="(component, index) in settings"
                :key="index"
                :class="component.settings == 'fullWidth' ? 'col-12' : 'col-6'"
            >
                <div class="w-100 d-flex">
                    <DropDownMenu
                        :width="component.settings"
                        :current_index="index"
                        :current_component="component.name"
                        @changeWidth="(width) => changeWidth(index)"
                        @change="(to) => change_com(index, to)"
                        @remove="remove_com(index)"
                    />
                    <div style="width: calc(100% - 35px)">
                        <b-card v-if="component.name == ''"> </b-card>
                        <component
                            v-else
                            :is="component.component"
                            @viewAccountDetails="viewAccountDetails"
                            v-bind="allElements[component.name].props || {}"
                            ref="account_details"
                            :current_item="current_item"
                            :time_frame="account_details_time_frame"
                            :parent_timeframe="timeframe"
                            :parent_start_date="startDate"
                            :parent_end_date="endDate"
                            :flag="flag"
                            :isLoading="isLoading"
                            @setStartDate="startDate = $event"
                            @setEndDate="endDate = $event"
                            @applyCustomTimeframeSingleItem="applyCustomTimeframeSingleItem"
                            @applyCustomTimeframe="applyCustomTimeframe"
                            @fetchAccountAnalysis="fetchAccountAnalysis"
                            @set_time_frame="set_time_frame"
                        />
                    </div>
                </div>
            </div>
        </draggable>

        <AddElement @add="(setting, com) => add_com(setting, com)" />
    </b-container>
</template>

<script>
import DropDownMenu from "./DropDownMenu.vue";
import AddElement from "./AddElement";

// Origin Components
import MarketExposureTable from "../b-book/marketExposure/MarketExposureTable.vue";
import PlatformOpenPositionsTable from "../b-book/marketExposure/PlatformOpenPositionsTable.vue";
import TradingAccountTable from "../b-book/marketExposure/TradingAccountTable.vue";

import PLTable from "../b-book/companyPL/PLTable.vue";
import ProfitLossChart from "../b-book/companyPLCharts/ProfitLossChart";
import EquityChart from "../b-book/companyPLCharts/EquityChart";
import DWChart from "../b-book/companyPLCharts/DWChart";
import TradingVolumeChart from "../b-book/companyPLCharts/TradingVolumeChart";

import AccountAnalysisTable from "../b-book/deepDrive/AccountAnalysis";
import AccountDetails from "../b-book/deepDrive/AccountDetails";
import AccountPLTable from "../b-book/deepDrive/AccountDetails";
import ClientEquityHistoryCharts from "../b-book/deepDriveCharts/ClientEquityHistoryCharts";
import ClientProfitHistoryCharts from "../b-book/deepDriveCharts/ClientProfitHistoryCharts";

// Custom Components
import CompanyPLWidgets from "./custom_components/CompanyPLWidgets";

import TodayUserStatisticsTable from "./custom_components/TodayUserStatisticsTable";
import CurrentUserStatisticsTable from "./custom_components/CurrentUserStatisticsTable";

import { BButton } from "bootstrap-vue";
import draggable from "vuedraggable";
import axios from "@axios";
import moment from "moment";
import riskStyle from "@/utils/riskStyle";
import elements from "./elements";
import { mapGetters } from "vuex";

export default {
    components: {
        MarketExposureTable,
        PlatformOpenPositionsTable,
        TradingAccountTable,
        TodayUserStatisticsTable,
        CurrentUserStatisticsTable,
        CompanyPLWidgets,
        PLTable,
        ProfitLossChart,
        EquityChart,
        DWChart,
        TradingVolumeChart,
        AccountAnalysisTable,
        AccountDetails,
        AccountPLTable,
        ClientEquityHistoryCharts,
        ClientProfitHistoryCharts,
        BButton,
        DropDownMenu,
        AddElement,
        draggable,
    },

    data() {
        return {
            elements,
            settings: [
                {
                    name: "market_exposure",
                    component: "MarketExposureTable",
                    settings: "fullWidth",
                },
                {
                    name: "platform_open_positions",
                    component: "PlatformOpenPositionsTable",
                    settings: "fullWidth",
                },
                {
                    name: "company_pl_widgets",
                    component: "CompanyPLWidgets",
                    settings: "fullWidth",
                },
            ],
            // forDeepDive Component
            current_item: null,
            account_details_time_frame: "ThisMonth",
            show_account_details: false,
            startDate: null,
            endDate: null,
            flag: true,
            timeframe: "",
            isLoading: false,
        };
    },

    computed: {
        ...mapGetters({
            timezoneOffset: "companySettings/timezoneOffset",
        }),

        allElements() {
            let res = {};
            Object.values(elements).forEach((ele) => {
                Object.entries(ele.children).forEach(([key, val]) => {
                    res[key] = val;
                });
            });
            return res;
        },
    },

    methods: {
        // Common
        riskStyle,

        remove_com(index) {
            this.settings.splice(index, 1);
            this.set_localStorage();
        },

        change_com(index, name) {
            this.$set(this.settings, index, {
                name: name,
                component: this.allElements[name]?.component,
                settings: this.settings[index].settings,
            });
            this.set_localStorage();
        },

        add_com(setting) {
            this.settings.push({
                name: "",
                component: "",
                settings: setting,
            });
            this.set_localStorage();
        },

        changeWidth(index) {
            this.$set(this.settings, index, {
                name: this.settings[index].name,
                component: this.settings[index].component,
                settings: this.settings[index].settings == "fullWidth" ? "halfWidth" : "fullWidth",
            });
            this.set_localStorage();
        },

        withoutInvalid(settings) {
            return settings?.filter((item, index) => {
                return !item.name || Object.keys(this.allElements).includes(item.name);
            });
        },

        set_localStorage() {
            localStorage.setItem("dashboard_settings", JSON.stringify(this.settings));
        },

        // For DeepDive Component
        set_time_frame(e) {
            this.account_details_time_frame = e;
        },
        update_metric_data(datum) {
            if (datum.metric_definition_name && ["AccountAnalysis"].includes(datum.metric_definition_name)) {
                if (datum.id == this.current_item.id) this.current_item = datum;
            }
        },
        async fetchAccountAnalysis() {
            try {
                this.isLoading = true;
                await this.$store.dispatch("tableData/fetchCurrentUserStatistics");
                await this.$store.dispatch("tableData/fetchAccountAnalysis");
                let res_aa = await axios.get(`${process.env.VUE_APP_API_URL}metric/AccountAnalysis`);
                this.startDate = moment.parseZone(res_aa.data.properties.from_date.value).format("Y-MM-DD");
                this.endDate = moment.parseZone(res_aa.data.properties.to_date.value).format("Y-MM-DD");
            } catch (err) {
                console.error(err);
            } finally {
                this.isLoading = false;
            }
        },
        async applyCustomTimeframe(startDate, endDate) {
            this.isLoading = true;
            // this.$emit('viewAccountDetails');
            // this.user_statistics.splice(0)
            let from_date = parseInt(
                moment
                    .utc(startDate)
                    .startOf("day")
                    .unix()
            );
            let to_date = parseInt(
                moment
                    .utc(endDate)
                    .endOf("day")
                    .unix()
            );
            // console.log(moment.utc(startDate).startOf('day'), moment.utc(endDate).endOf('day'))
            axios
                .post(`${process.env.VUE_APP_API_URL}metric/AccountAnalysis/update_custom_timeframe`, {
                    from_date: from_date,
                    to_date: to_date,
                })
                .then((res) => {
                    this.fetchAccountAnalysis();
                })
                .catch((err) => {
                    console.error(err.response);
                    this.isLoading = false;
                });
        },
        applyCustomTimeframeSingleItem(startDate, endDate, afterSuccess, afterFailure) {
            // console.log(startDate, endDate)
            let from_date = parseInt(
                moment(startDate)
                    .startOf("day")
                    .format("X")
            );
            let to_date = parseInt(
                moment(endDate)
                    .endOf("day")
                    .format("X")
            );
            axios
                .post(`${process.env.VUE_APP_API_URL}metric/AccountAnalysis/update_custom_timeframe`, {
                    from_date: from_date,
                    to_date: to_date,
                    metric_datum_id: this.current_item.id,
                })
                .then((res) => {
                    this.current_item = res.data.account;
                    this.$refs.account_details.update_account_symbols(res.data.account_symbols);
                    if (afterSuccess) afterSuccess(res);
                })
                .catch((err) => {
                    console.error(err.response);
                    if (afterFailure) afterFailure(err);
                });
        },
        viewAccountDetails(item, timeframe) {
            if (!item) this.show_account_details = false;
            else {
                // console.log("viewAccountDetails", item)
                this.current_item = item;
                this.timeframe = timeframe;
                this.from_date = this.startDate;
                this.to_date = this.endDate;
                this.show_account_details = true;
                this.flag = !this.flag;
            }
        },
    },

    channels: {
        MetricDataUpdateChannel: {
            connected() {
                console.log("Connected to the MetricDataUpdateChannel channel");
            },
            rejected() {
                console.log("Rejected to the MetricDataUpdateChannel channel");
            },
            received(data) {
                if (data?.event_name == "trade.MetricDatum.before_destroy") {
                    this.$store.dispatch("tableData/removeMetricDatum", data.payload);
                    return;
                }

                if (data?.event_name == "trade.MetricDatum.after_create") {
                    this.$store.dispatch("tableData/updateMetricDatum", data.payload);
                    return;
                }

                if (Array.isArray(data)) {
                    data.forEach((datum) => {
                        this.$store.dispatch("tableData/updateMetricDatum", datum);
                    });
                }
                this.$store.commit("app/SET_DATA_LIVE_TIME", moment().format(" HH:mm:ss - DD/MM/YYYY"));
            },
            disconnected() {
                console.log("Disconnected to the MetricDataUpdateChannel channel");
            },
        },
    },

    mounted() {
        //? mockData
        // this.$cable.subscribe({
        //   channel: 'MetricDataUpdateChannel'
        // });

        let local_settings = JSON.parse(localStorage.getItem("dashboard_settings"));
        local_settings = this.withoutInvalid(local_settings);
        if (local_settings) this.settings = local_settings;
        else this.set_localStorage();
        // console.log(this.settings)
    },
};
</script>
