 <template>
  <div class="dropdown-dashboard">
    <b-dropdown no-caret variant="flat" style="top: -3px; background: '#5A5A5A'" toggle-class="p-0 btn-icon">
      <template #button-content>
        <font-awesome-icon 
          :icon="['far', 'ellipsis-v']" class="font-medium-3" color="#B0B3B8" 
          @click="openLevel1 = false"
        />
      </template>

      <b-dropdown-item @click="$emit('remove')"> 
        <font-awesome-icon :icon="['far', 'times']" size="lg" class=" mr-1 text-danger"/> 
        Remove 
      </b-dropdown-item>

      <b-dropdown-item @click="$emit('changeWidth')"> 
        <font-awesome-icon 
          :icon="['far', width == 'halfWidth' ? 'expand-alt' : 'compress-alt']" 
          size="lg" class="text-warning" style="margin-right: 8px"
        /> 
          Set {{width == 'halfWidth' ? 'Full' : 'Half'}} Width 
      </b-dropdown-item>
      
      <div class="pl-1 li-padding"> 
        Change Element  
        <font-awesome-icon 
          :icon="['fas', openLevel1 ? 'caret-down' : 'caret-right']" 
          size="lg" class="mx-tiny cursor-pointer" 
          @click="() => { openLevel1 = !openLevel1; openLevel2 = ''}"
        />
          
        <div v-if="openLevel1">
          <div class="pl-3 li-padding" v-for="(item, key) in elements" :key="key"> 
            {{item.name}} 
            <font-awesome-icon 
              :icon="['fas', openLevel2 == key ? 'caret-down' : 'caret-right']" size="lg" 
              class="mx-tiny cursor-pointer" 
              @click="openItem(key)"
            />
            <div v-if="openLevel2 == key">
              <b-dropdown-item 
                v-for="(child, child_key) in item.children" :key="child_key" 
                @click="$emit('change', child_key)
              "> 
                <div class="d-flex pl-1">
                  <div :class="current_component == child_key ? 'circle-active' : 'circle'"></div>
                  <div>{{child.name}}</div> 
                </div>
              </b-dropdown-item>
            </div>
          </div>
        </div>
      </div>

    </b-dropdown>
  </div>
</template>
<script>
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import elements from "./elements"

export default {
  components: { BDropdown, BDropdownItem },
  data() {
    return {
      openLevel1: false,
      openLevel2: '',
      elements
    };
  },

  props: ["current_component", "width"],
  mounted() {},

  methods: {
    openItem(item_name) {
      this.openLevel2 = this.openLevel2 == item_name ? '' : item_name
    },
  }
};
</script>
<style>
.dark-layout .dropdown-dashboard .dropdown-menu {
  background-color: #5a5a5a !important;
  width: 320px;
}

.dark-layout .dropdown-dashboard .dropdown-menu, .dark-layout .dropdown-dashboard .dropdown-menu .dropdown-item{
  color: rgba(255, 255, 255, 0.75);
}


.dropdown-dashboard .dropdown-item:hover {
    background-color: #28c76f!important;
}

.mx-tiny {
  margin: 0 4px;
}

.li-padding {
  padding: 0.65rem 0.55rem
}

.circle {
  margin-right: 10px;
  border: 1px solid white;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  top: 5px;
  position: relative;
}

.circle-active {
  margin-right: 10px;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  top: 5px;
  background: yellowgreen;
  position: relative;
}
</style>