<template>
  <div class="w-100 mb-1">
    <h4 class="mb-1 table-title">Company PL</h4>
    <StatisticCards />
  </div>
</template>

<script>
import StatisticCards from "../../b-book/companyPL/StatisticCards";
export default {
  components: { StatisticCards },
};
</script>